import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
import api from '../apis/api.js'
import {
    Select, MenuItem, Button, Dialog,
    DialogContent, DialogContentText, DialogTitle, DialogActions,
    IconButton, Tooltip, TextField,
    Menu
} from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useParams } from 'react-router-dom';

const CheckinGoals = ({ checkinGoalData, setCheckinGoalData, checkin }) => {
    const { playerId, checkinId } = useParams()
    const [goals, setGoals] = useState([])
    const [editingId, setEditingId] = useState(null)
    const [openDialogGoalComment, setOpenDialogGoalComment] = useState(false)
    const [goalComment, setGoalComment] = useState('')
    const [goalSelectedToComment, setGoalSelectedToComment] = useState([])

    const handleCloseDialog = () => {
        setOpenDialogGoalComment(false)
    }

    const handleOpenDialog = (goal) => {
        if (!goal.goal_comments) {
            setGoalSelectedToComment(goal)
            setOpenDialogGoalComment(true)
            setGoalComment('')
        } else {
            setGoalSelectedToComment(goal)
            setOpenDialogGoalComment(true)
            setGoalComment(goal.goal_comments)
        }
    }

    const handleClose = () => {
        setOpenDialogGoalComment(false)
    }

    const handleStatusChange = (id, value) => {
        const newGoalsData = []
        goals.map((goal) => {
            if (goal.id === id) {
                goal.check_in_goal_status = value
            }
            newGoalsData.push(goal)
        })
        setEditingId(null)
        setGoals(newGoalsData)
        setCheckinGoalData((prevStates) => prevStates.map((goal) => goal.id === id ? {
            ...goal,
            check_in_goal_status: value
        } : goal))
    }

    const handleCommentsChange = (id, value) => {

        console.log('id', id)
        console.log('value', value)

        const newGoalsData = []
        goals.map((goal) => {
            if (goal.id === id) {
                goal.goalComments = value
            }
            newGoalsData.push(goal)
        })
        setEditingId(null)
        setGoals(newGoalsData)
        setCheckinGoalData((prevStates) => prevStates.map((goal) => goal.id === id ? {
            ...goal,
            goal_comments: value
        } : goal))
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true
        },
        {
            field: 'newComments',
            headerName: '',
            width: 50,
            renderCell: (params) => {
                return <div>
                    <Tooltip title='Add Comment'>
                        <IconButton onClick={() => {
                            handleCommentsChange(params.row.id, params.row.goalComments)
                            handleOpenDialog(params.row)
                        }}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }

        },
        { field: 'goalTitle', headerName: 'Goal Title', width: 150, flex: 2 },
        { field: 'targetDate', headerName: 'Target Date', width: 150, flex: 1 },
        { field: 'lastCheckinDate', headerName: 'Last Check-in Date', width: 150, flex: 1 },
        {
            field: 'check_in_goal_status',
            headerName: 'Check-in Status',
            editable: true,
            width: 150,
            renderCell: (params) => {
                // const isEditing = params.id === editingId;
                // return isEditing ? 
                return (
                    <Select
                        value={params.value}
                        onChange={(e) => handleStatusChange(params.id, e.target.value)}
                        onBlur={() => setEditingId(null)}
                        fullWidth
                        sx={{ height: '80%' }}
                        autoFocus
                    >
                        {params.value}
                        <MenuItem value="Complete">Complete</MenuItem>
                        <MenuItem value="Delayed">Delayed</MenuItem>
                        <MenuItem value="Not Started">Not Started</MenuItem>
                        <MenuItem value="On Track">On Track</MenuItem>

                    </Select >
                )
                // : (
                //     <div>
                //         {params.value}
                //     </div>
                // );
            }
        },
        {
            field: 'hasComments',
            headerName: '',
            renderCell: (params) => {
                return params.row.goalComments || params.row.goal_comments  ?
                    <div>
                        <Tooltip title='Has Comments'>
                            <IconButton onClick={() => {
                                handleOpenDialog(params.row)
                            }}>
                                <CommentIcon />
                            </IconButton>
                        </Tooltip>
                    </div> : null
            }
        },

    ]

    const fetchGoals = async () => {
        const goalData = []
        const response = await api.get('/goals')
        const data = response.data
        let dataFiltered = data.filter((goal) => goal.people_builder.player_id === playerId)
        let checkinGoalsIds = checkin?.check_in_goals?.map((goal) => goal.goal_id)

        if (dataFiltered.length === 0) {
            dataFiltered = data.filter((goal) => checkinGoalsIds?.includes(goal.id))
        }

        dataFiltered.map((goal) => {
            goalData.push({
                id: goal.id,
                goal_id: goal.id,
                goalTitle: goal.goal_name,
                goalType: goal.goal_term,
                targetDate: goal.target_date,
                lastCheckinDate: goal.last_checkin_date,
                check_in_goal_status: goal.checkin_status,
                goal_comments: goal.goal_comments
            })
        })
        setGoals(goalData)
        setCheckinGoalData(goalData)
    }

    const getCheckinGoals = async () => {
        const goalData = []
        checkin?.check_in_goals?.map((goal) => {
            goalData.push({
                id: goal.id,
                goal_id: goal.goal_id,
                goalTitle: goal.goal.goal_name,
                goalType: goal.goal.goal_term,
                targetDate: goal.goal.target_date,
                lastCheckinDate: goal.updated_at.split('T')[0],
                check_in_goal_status: goal.check_in_goal_status,
                goal_comments: goal.goal_comments
            })
        })
        setGoals(goalData)
        setCheckinGoalData(goalData)
    }

    useEffect(() => {
        if (!checkinId) {
            fetchGoals()
        } else {
            getCheckinGoals()
        }
    }, [checkin])

    useEffect(() => {
        console.log('goals', goals)

    }, [goals])

    return (
        <>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    }
                }}
                rows={goals}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                onCellClick={(params) => {
                    if (params.field === 'check_in_goal_status') {
                        setEditingId(params.id)
                    }
                }}
                // onRowClick={(params) => setEditingId(params.id)}
                onCellEditCommit={() => setEditingId(null)}

            />
            <Dialog
                open={openDialogGoalComment}
                onClose={handleClose}
                fullWidth
            >
                <DialogContent>
                    <DialogTitle sx={{ textAlign: 'center' }}>{goalSelectedToComment?.goalTitle}</DialogTitle>
                    <TextField
                        label="Goal Comment"
                        fullWidth
                        value={goalComment || goalSelectedToComment?.goalComments}
                        onChange={(e) => {
                            setGoalComment(e.target.value)
                        }}
                    />
                    <DialogActions>
                        <Button
                            onClick={handleCloseDialog}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={(e, value) => {
                                handleCommentsChange(goalSelectedToComment.id, goalComment)
                                handleCloseDialog()
                            }}
                            variant="outlined"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>

    )
}

export default CheckinGoals
