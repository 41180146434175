import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import { Typography, TextField, Avatar, Paper, Button, Stack } from '@mui/material';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useState } from 'react'

export default function CommentsTimeline(props) {
    const { setComment, comment, comments, handleAddComment } = props
    const [textfieldHelpText, setTextfieldHelpText] = useState('')
    const [errorTextfield, setErrorTextfield] = useState(false)

    const handleClickAddComment = () => {
        if (comment === '') {
            setTextfieldHelpText(`Comment should not empty`)
            setErrorTextfield(true)
            return
        }

        handleAddComment()

    }

    return (
        <Paper elevation={4}>
            <Timeline position='left' sx={{
                [`& .${timelineContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
            >
                {comments?.map((item, index) => (

                    <TimelineItem key={index} sx={{ width: '60%' }} >
                        <TimelineOppositeContent
                            sx={{
                                m: 'auto 0',
                                fontSize: '11px'
                            }}
                            align="left"
                            variant="body2"
                            color="text.secondary"

                        >
                            {item.comment}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <Avatar src={item.avatar} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <Stack alignItems='center'>
                            <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '11px' }}>
                                    {item.name}
                                </Typography>
                                <Typography sx={{ fontSize: '11px' }} color={'text.secondary'} >{item.commentDate}</Typography>
                            </TimelineContent>
                        </Stack>
                    </TimelineItem>
                ))}
            </Timeline >
            <Stack
                m={2}
                direction='row'
                spacing={2}
            >
                <TextField
                    sx={{ width: '80%' }}
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    rows={2}
                    maxRows={2}
                    value={comment}
                    variant='outlined'
                    onChange={(e) => {
                        setTextfieldHelpText('')
                        setErrorTextfield(false)
                        setComment(e.target.value)
                    }}
                    helperText={textfieldHelpText}
                    error={errorTextfield}

                />
                <Button
                    variant='outlined'
                    sx={{ width: '20%' }}
                    onClick={() => {
                        handleClickAddComment()
                    }}
                >
                    Add Comment
                </Button>
            </Stack>
        </Paper>
    );
}
