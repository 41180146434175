import React from 'react'
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

const SnackbarAlert = ({open, severityType, messageText, handleClose}) => {

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            open={open}
            onClose={handleClose}
        >
            <Alert
                severity={severityType}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {messageText}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarAlert