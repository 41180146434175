import {
    Button, Container, Dialog, IconButton, Stack,
    DialogTitle, DialogContent, DialogContentText, DialogActions,
    Card, Typography, Chip, Switch, TextField,
    TableContainer, Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    ImageList,
    ImageListItem,
    CardContent
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../apis/api.js';
import moment from 'moment';
import SnackbarAlert from "./SnackbarAlert.jsx"
import BackdropLoader from './BackdropLoader.jsx'

const AdditionalInformation = (props) => {
    const { peopleBuilder } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [checked, setChecked] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [playerComments, setPlayerComments] = useState('')
    const [coachComments, setCoachComments] = useState('')
    const [descriptionFile, setDescriptionFile] = useState('')
    const [urlFile, setUrlFile] = useState('')
    const [files, setFiles] = useState([])
    const [textMeeting, setTextMeeting] = useState(null)
    const [colorIconMeeting, setColorIconMeeting] = useState(null)
    const [descriptionRef, setDescriptionRef] = useState(null);
    const [urlRef, setUrlRef] = useState(null);
    const [additionalInformation, setAdditionalInformation] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
    const [openBackdrop, setOpenBackdrop] = useState(false)

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked)
        setSelectedDate(null)
    }

    const handleDeleteFile = (id) => {
        const newFiles = files.filter(file => file.id !== id)
        setFiles(newFiles)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleCloseAlertConfig = () => {
        setAlertConfig({ open: false })
    }

    const createFiles = () => {

        if (!descriptionFile.trim()) {
            descriptionRef?.focus();
            return;
        }

        if (!urlFile.trim()) {
            urlRef?.focus();
            return;
        }

        setFiles(prevState => ([...prevState, {
            id: files.length + 1,
            description: descriptionFile,
            url: urlFile,
            uploaded: new Date().toLocaleDateString('en-ca')
        }]))
        setDescriptionFile('')
        setUrlFile('')
    }

    const handleCreateAddInformation = async (id) => {
        const data = {
            player_people_builder_id: peopleBuilder.id,
            had_meeting: selectedDate ? true : false,
            meeting_date: selectedDate,
            player_comments: playerComments,
            coach_id: peopleBuilder.coach.id,
            coach_comments: coachComments,
            files: JSON.stringify(files)
        }

        setOpenDialog(false)

        try {
            if (!additionalInformation[0]?.id) {
                const response = await api.post('/additional-information', data)
                if (response.status === 200) {
                    setAlertConfig({
                        open: true,
                        messageText: 'Additional Information created successfully',
                        severityType: 'success'
                    })
                    setOpenBackdrop(false)
                } else {
                    setAlertConfig({
                        open: true,
                        messageText: `Something was wrong. Status: ${response.status}`,
                        severityType: 'error'
                    })
                    setOpenBackdrop(false)
                }
            } else {
                updateAddInformation(additionalInformation[0]?.id)
            }
            getAddInformation()
        } catch (error) {
            console.log(error)
            setAlertConfig({
                open: true,
                messageText: `Something was wrong. Status: ${error.status}`,
                severityType: 'error'
            })
        }
    }

    const updateAddInformation = async (id) => {
        setOpenBackdrop(true)
        const data = {
            player_people_builder_id: peopleBuilder.id,
            had_meeting: selectedDate ? true : false,
            meeting_date: selectedDate,
            player_comments: playerComments,
            coach_id: peopleBuilder.coach.id,
            coach_comments: coachComments,
            files: JSON.stringify(files)
        }
        try {
            const response = await api.post(`/additional-information/${id}`, data)

            if (response.status === 200) {
                setAlertConfig({
                    open: true,
                    messageText: 'Additional Information was updated successfully',
                    severityType: 'success'
                })
                setOpenBackdrop(false)
            } else {
                setAlertConfig({
                    open: true,
                    messageText: `Something was wrong. Status: ${response.status}`,
                    severityType: 'error'
                })
                setOpenBackdrop(false)
            }

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            setAlertConfig({
                open: true,
                messageText: `Something was wrong. Status: ${error.status}`,
                severityType: 'error'
            })
        }

    }

    const getAddInformation = async () => {

        try {
            const response = await api.get('/additional-information')
            const addInfo = response.data.filter(info => info.player_people_builder_id === peopleBuilder.id)

            if (addInfo.length) {
                setAdditionalInformation(addInfo)
                setPlayerComments(addInfo[0].player_comments)
                setCoachComments(addInfo[0].coach_comments)
                setFiles(JSON.parse(addInfo[0].files))
                setChecked(addInfo[0].had_meeting)
                setSelectedDate(moment(addInfo[0].meeting_date?.split('T')[0]))
                setColorIconMeeting('green')
                addInfo[0].had_meeting ? setTextMeeting(`Meeting 1:1 on ${addInfo[0].meeting_date?.split('T')[0]}`) : setTextMeeting('No Meeting 1:1')
            } else {
                setColorIconMeeting('grey')
                setTextMeeting('No Meeting 1:1')
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAddInformation()
    }, [])

    return (
        <Container>
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleCloseAlertConfig}
            />
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'right' }}>
                <IconButton onClick={() => setOpenDialog(true)}>
                    <InfoIcon sx={{ color: colorIconMeeting, fontSize: 18 }} />
                </IconButton>
                <Button onClick={() => setOpenDialog(true)} sx={{ fontSize: 10, color: colorIconMeeting }} >
                    {textMeeting}
                </Button>
            </Stack>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth={true}
                sx={{
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    overflowY: 'auto'
                }}
            >

                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <ImageListItem sx={{ width: '3rem' }}>
                            <img src="https://iili.io/JazaDEg.jpg" alt="" />
                        </ImageListItem>
                        <Typography variant="h5" color="initial">People Builder: End of Goal Settings Phase</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box elevation={2} sx={{ mt: 2, mb: 2, p: 2, minHeight: 90 }}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} variant='span'>Did Coach and Player have a 1:1 meeting during the Goals setting process?</Typography>
                                    <Chip color={!checked ? 'error' : 'default'} sx={{ ml: 2, mr: 2, cursor: 'pointer' }} label='No' />
                                    <Switch
                                        color="default"
                                        checked={checked}
                                        onChange={handleSwitchChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Chip color={checked ? 'success' : 'default'} sx={{ ml: 2, cursor: 'pointer', mr: 2 }} label='Yes' />
                                </Stack>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker
                                        label="Meeting Date"
                                        sx={{ visibility: checked ? 'visible' : 'hidden', width: '50%' }}
                                        value={selectedDate}
                                        onChange={(newValue) => {
                                            setSelectedDate(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        format='YYYY-MM-DD'
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack
                                sx={{ marginTop: '2rem' }}
                                // direction='row' 
                                spacing={2}
                            >
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    id="players-comments"
                                    label="Player's Comments"
                                    value={playerComments}
                                    onChange={(e) => {
                                        setPlayerComments(e.target.value)
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    sx={{ mt: 4 }}
                                    fullWidth
                                    id="coach-comments"
                                    label="Coach's Comments"
                                    value={coachComments}
                                    onChange={(e) => {
                                        setCoachComments(e.target.value)
                                    }}
                                />
                            </Stack>
                            <Card sx={{ mt: 4, p: 2 }}>
                                <CardContent>
                                    <Stack direction='row' spacing={2}>
                                        <TextField
                                            variant="standard"
                                            id="description-file"
                                            label="Description"
                                            sx={{ width: '40%' }}
                                            value={descriptionFile}
                                            onChange={(e) => {
                                                setDescriptionFile(e.target.value)
                                            }}
                                            inputRef={setDescriptionRef}
                                        />
                                        <TextField
                                            variant="standard"
                                            id="url-file"
                                            label="URL"
                                            fullWidth
                                            value={urlFile}
                                            onChange={(e) => {
                                                setUrlFile(e.target.value)
                                            }}
                                            inputRef={setUrlRef}
                                        />
                                        <Button onClick={createFiles} variant='outlined'>Upload</Button>
                                    </Stack>
                                    <TableContainer sx={{ mt: 6 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>File</TableCell>
                                                    <TableCell>Uploaded At</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {files.map((file, index) => (
                                                    <TableRow
                                                        onClick={() => {
                                                            window.open(file.url, '_blank')
                                                        }}
                                                        key={index}
                                                        hover
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell>{file.description}</TableCell>
                                                        <TableCell>{file.uploaded}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title='Delete File'>
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        handleDeleteFile(file.id)
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Box>
                        <Stack>
                            <Typography variant="overline">Player: {peopleBuilder.player.full_name}</Typography>
                            <Typography variant="overline">Coach: {peopleBuilder.coach.full_name}</Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button variant='contained' color='success' onClick={() => handleCreateAddInformation(peopleBuilder.id)}>Save</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default AdditionalInformation