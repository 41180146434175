import React, { useContext, useEffect, useState, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { InputBase, FormGroup, FormControlLabel, Checkbox, Stack, Card, CardContent, Container } from '@mui/material';
import { EmployeesContext } from '../context/UsersContext';
import SearchIcon from '@mui/icons-material/Search.js'
import { styled, alpha } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '30%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    // backgroundColor: alpha(theme.palette.common.black, 0.15),
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const ListUsersWithDataGrid = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState('')
    const [employeesFiltered, setEmployeesFiltered] = useState([])
    const [checked, setChecked] = useState(false)
    const location = useLocation()
    const currentPath = location.pathname
    const navigate = useNavigate()

    const usersDataFixed = () => {
        let usersEtroTeam

        if (search) {
            usersEtroTeam = employees.filter(employee => (
                (
                    employee?.work_location?.building_name?.toLowerCase().includes(search.toLowerCase()) ||
                    employee.full_name.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.coach.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.title?.title?.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.department?.department?.toLowerCase().includes(search.toLowerCase())
                ) &&
                employee?.etro_team !== checked
            ))

        } else {
            usersEtroTeam = employees.filter(employee => employee.etro_team !== checked)
        }

        const users = usersEtroTeam.map(employee => ({
            id: employee.id,
            is_admin: employee?.is_delegated_admin,
            full_name: employee?.full_name ?? '',
            title: employee?.title?.title ?? '',
            department: employee?.department?.department ?? '',
            coach: employee?.coach ?? '',
            work_location: employee?.work_location?.building_name ?? '',
            phone: employee?.phone ?? ''
        }))
        setRows(users)
    }

    useEffect(() => {
        usersDataFixed()
    }, [search, checked, employees])

    useEffect(() => {

        const results = search.length > 0
            ? employees.filter(employee => (employee.full_name.toLowerCase().includes(search.toLowerCase()) || employee.coach.toLowerCase().includes(search.toLowerCase())) && employee.etro_team !== checked)
            : employees.filter(employee => employee.etro_team !== checked)

        setEmployeesFiltered(results)
    }, [checked, search, employees])

    const handleUserToProfile = useCallback((user) => {

        if (currentPath === '/') {
            navigate(`users/profile/${user.id}`)
        } else {
            navigate(`profile/${user.id}`)
        }
    })

    const columns = [
        {
            field: 'full_name',
            headerName: 'Name',
            width: 250,
            headerClassName: 'users-header',
            renderCell: (params) => (
                <div>
                    {params.value}
                    {params.row.is_admin && (
                        <img style={{ width: '20px', marginLeft: '10px' }} src='https://iili.io/Jazab4a.png' />
                    )}
                </div>
            )
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'coach',
            headerName: 'Coach',
            description: 'This column has a value getter and is not sortable.',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'work_location',
            headerName: 'Work Location',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'phone',
            headerName: 'Contact Phone',
            width: 164,
            sortable: false,
            headerClassName: 'users-header',
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ mt: 4 }}>
            <Card sx={{ maxHeight: '90vh' }}>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                        </Search>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Not ETRO Team"
                                onChange={(event) => {
                                    setChecked(event.target.checked)
                                }} />
                        </FormGroup>

                    </Stack>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        row
                        onRowClick={(e) => handleUserToProfile(e)}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 13,
                                },
                            },
                        }}
                        sx={{
                            // minHeight: 200,
                            '& .users-header': {
                                backgroundColor: '#ccc',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        }}
                        pageSizeOptions={[5]}
                    />
                </CardContent>
            </Card>
        </Container>
    )
}

export default ListUsersWithDataGrid