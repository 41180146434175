import React, { useEffect, useState, useRef } from 'react';
import { Box, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SnackbarAlert from './SnackbarAlert.jsx';


const GoalSteps = (props) => {
    const [activeStep, setActiveStep] = useState(0)
    const { steps, setSteps, onSaveSteps, disabled, setGoalId } = props
    const [finishSteps, setFinishSteps] = useState(false)
    const [editSteps, setEditSteps] = useState(false)
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
	
    const handleNext = () => {
		
		if (!steps[activeStep].step) {
            setAlertConfig({
                messageText: 'Empty Step',
                severityType: 'error',
                open: true
            })
            return
        }

        activeStep + 1 === steps.length ?

            setSteps(prevSteps => {
                const newSteps = [...prevSteps]
                newSteps[activeStep].label = newSteps[activeStep].step
                newSteps.push({
                    step_order: newSteps.length,
                    label: `Step ${newSteps.length + 1}`,
                    step: ''
                })
                return newSteps;
            }) :
            setSteps(prevSteps => {
                const newSteps = [...prevSteps]
                newSteps[activeStep].label = newSteps[activeStep].step
                return newSteps;
            })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
		
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        setSteps(prevSteps => {
            const newSteps = [...prevSteps]
            newSteps[activeStep - 1].label = newSteps[activeStep - 1].step
            return newSteps;
        })

    }

    const handleDelete = (index) => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        setSteps(prevSteps => {
            const newSteps = [...prevSteps];
            newSteps.splice(index, 1);
            return newSteps.map((step, newIndex) => ({
                ...step,
                label: step.step,
                step_order: newIndex
            }));
        });
    }

    const updateStepText = (index, text) => {
        const newSteps = steps.map((step, i) =>
            i === index ? { ...step, step: text, step_order: i } : step
        )

        setSteps(newSteps)
        onSaveSteps('steps', newSteps)

    }

    const handleClose = () => {
        setAlertConfig({ open: false })
    };

    const StepsFromGoal = (props) => {
        return (
            <Stepper activeStep={steps.length} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.step_order}>
                        <StepLabel>{step.step}</StepLabel>
                    </Step>
                ))}
                {finishSteps || !editSteps && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                setEditSteps(true)
                                setActiveStep(0)
                            }}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            Edit
                        </Button>
                    </Paper>
                )}
            </Stepper>
        )
    }

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Stepper activeStep={editSteps ? activeStep : steps.length} orientation="vertical">
                {editSteps ? (
                    steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.step}
                            </StepLabel>
                            <StepContent>
                                <TextField variant="outlined"
                                    fullWidth
									autoFocus={true}
                                    value={steps[index].step}
                                    onChange={(e) => {
                                        updateStepText(index, e.target.value)

                                    }}
                                    placeholder='Type here'

                                />
                                <Button variant="outlined"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Next
                                </Button>
                                <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (!steps[activeStep].step) {
                                            setAlertConfig({
                                                messageText: 'Empty Step',
                                                severityType: 'error',
                                                open: true
                                            })
                                            return
                                        }
                                        setActiveStep(steps.length)
                                        setFinishSteps(true)
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Finish
                                </Button>
                                <Button
                                    disabled={index === 0}
                                    onClick={() => handleDelete(index)}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Delete
                                </Button>
                            </StepContent>
                        </Step>
                    ))
                ) : (
                    <StepsFromGoal />
                )
                }
            </Stepper>
            {activeStep >= steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                    </Button>
                </Paper>
            )}
        </Box>
    );
}

export default GoalSteps