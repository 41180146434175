import React, { useEffect, useState } from 'react'
import {
	Table, TableHead, TableRow,
	TableCell, TableBody, Fade, Stack, Fab,
	Paper, Dialog, DialogTitle, DialogContent, DialogContentText,
	DialogActions, Button, IconButton, Tooltip, Box
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit'
import api from '../apis/api';
import SnackbarAlert from './SnackbarAlert.jsx'
import BackdropLoader from './BackdropLoader.jsx'
import AdditionalInformation from './AdditionalInformation.jsx'

const Goals = (props) => {
	const { playerId, goals, getGoals, peopleBuilder } = props
	const [focusedRow, setFocusedRow] = useState(null)
	const navigate = useNavigate();
	const [openDelete, setOpenDelete] = useState(false)
	const [goalSelectedToDelete, setGoalSelectedToDelete] = useState({ goal_name: '', id: '' })
	const [openBackdrop, setOpenBackdrop] = useState(false)
	const [alertConfig, setAlertConfig] = useState({
		messageText: '',
		severityType: '',
		open: false
	})
	const [userLogged] = useState(JSON.parse(localStorage.getItem('user')))
	const [isCoach] = useState(userLogged.id === peopleBuilder.coach_id)
	const [isPlayer] = useState(userLogged.id === peopleBuilder.player_id)

	const handleClose = () => {
		setAlertConfig({ open: false })
	}

	const handleRowClick = (goalId, peopleBuilderId) => {
		const goalSelected = goals.find(goal => goal.id === goalId)
		if (goalSelected.goal_status === 'Draft Goal' && !isPlayer) {
			setAlertConfig({
				messageText: 'This goal is not finished yet',
				open: true,
				severityType: 'error'
			})
			return
		}
		
		navigate(`/goal-form/people-builder/${peopleBuilderId}/goal/${goalId}`)
	}

	const openGoalForm = () => {
		const filterGoalsByUser = goals.filter(goal => goal.people_builder.player_id === playerId)

		if (!filterGoalsByUser.length) {
			navigate(`/first-goal-pdf/${peopleBuilder?.id}`)
		} else {
			navigate(`/goal-form/people-builder/${peopleBuilder?.id}`)
		}

	}

	const userGoals = goals.filter(goal => goal.people_builder.player_id === playerId)

	const deleteGoal = async () => {
		setOpenBackdrop(true)
		try {
			const response = await api.delete(`/goals/${goalSelectedToDelete.id}`)
			setOpenBackdrop(false)
			setAlertConfig({
				messageText: 'Goal deleted successfully',
				severityType: 'success',
				open: true
			})
			setOpenDelete(false)
			getGoals()
		} catch (error) {
			setOpenBackdrop(false)
			setAlertConfig({
				messageText: 'Something was wrong',
				severityType: 'error',
				open: true
			})
			console.log(error)

		}
	}

	return (
		<Paper>
			{openBackdrop && (
				<BackdropLoader openBackdrop={open} />
			)}
			<SnackbarAlert
				open={alertConfig.open}
				messageText={alertConfig.messageText}
				severityType={alertConfig.severityType}
				handleClose={handleClose}
			/>
			<AdditionalInformation peopleBuilder={peopleBuilder} />
			<Table size="small" aria-label="purchases">
				<TableHead >
					<TableRow sx={{ backgroundColor: '#ccc' }} >
						<TableCell>Goal Name</TableCell>
						<TableCell>Goal Type</TableCell>
						<TableCell>Target Date</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Last Check-In</TableCell>
						<TableCell>Check-In Status</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{userGoals.map((goal) => (
						<TableRow
							sx={{ cursor: 'pointer' }}
							hover
							key={goal.id}
							onMouseEnter={() => {
								setFocusedRow(goal.id)
							}}
							onMouseLeave={() => setFocusedRow(null)}
							onClick={(event) => {
								if (!event.target.closest('.delete-icon')) {
									handleRowClick(goal.id, goal.people_builder_id)
								}
							}}
						>
							<TableCell>{goal.goal_name}</TableCell>
							<TableCell>{goal.goal_term}</TableCell>
							<TableCell>{goal.target_date.split('T')[0]}</TableCell>
							<TableCell>{goal.goal_status}</TableCell>
							<TableCell>{goal.updatedAt.split('T')[0]}</TableCell>
							<TableCell>{goal.checkin_status}</TableCell>
							<TableCell>
								<>
									<Fade in={focusedRow === goal.id} timeout={200}>
										<Stack direction='row' spacing={1}>
											<IconButton>
												<Tooltip title='Edit Goal'>
													<EditIcon
														onClick={(event) => handleRowClick(goal.id, goal.people_builder_id)}
													/>
												</Tooltip>
											</IconButton>
											<IconButton>
												<Tooltip title='Delete Goal'>
													<DeleteIcon
														className="delete-icon"
														onClick={() => {
															setOpenDelete(true)
															setGoalSelectedToDelete({ goal_name: goal.goal_name, id: goal.id })
														}} />
												</Tooltip>
											</IconButton>
										</Stack>
									</Fade>
								</>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{isCoach || isPlayer ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
					<Tooltip title='Add New Goal'>
						<IconButton onClick={openGoalForm}>
							<AddCircleOutlineIcon />
						</IconButton>
					</Tooltip>
				</Box>
			) : (
				<Tooltip title={`You do not have access to create new Goal`}>
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<IconButton disabled onClick={openGoalForm}>
							<AddCircleOutlineIcon />
						</IconButton>
					</Box>
				</Tooltip>
			)}
			<Dialog
				open={openDelete}
				// onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You are about to delete {goalSelectedToDelete.goal_name}.
						Are you sure you want to do this?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDelete(false)}>Cancel</Button>
					<Button onClick={deleteGoal}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	)
}

export default Goals