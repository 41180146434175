import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, Paper, Container, Avatar, Stack,
    Card, Autocomplete, TextField, Fade, CardContent, Tooltip,
    Chip

} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Goals from './Goals.jsx';
import api from '../apis/api.js';
import BackdropLoader from './BackdropLoader.jsx'
import styles from '../css/List-users.module.css';
import InputSearch from './InputSearch.jsx'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckIn from './CheckIns.jsx';
import RequiredCheckIn from './RequiredCheckIn.jsx';
import { EmployeesContext } from '../context/UsersContext.js';

const getStatusPeopleBuilder = (goals) => {
    let status = 'No Goals'
    const statusGoals = new Set()
    goals.map(goal => statusGoals.add(goal.goal_status))

    if (statusGoals.has('Draft Goal')) {
        status = 'Draft Goal'
    } else if (statusGoals.has('Approval')) {
        status = 'Approval'
    } else if (statusGoals.has('Needs Revision')) {
        status = 'Needs Revision'
    } else if (statusGoals.has('Active')) {
        status = 'Active'
    } else if (statusGoals.has('Completed')) {
        status = 'Completed'
    } else {
        status = 'No Goals'
    }

    return status
}

const Row = React.forwardRef((props, ref) => {
    const { playerId, goals, peopleBuilder, getGoals, pbStatus, setPbStatus } = props;
    const [open, setOpen] = React.useState(false);
    const [colorChip, setColorChip] = useState('grey')
    const [colorTextChip, setColorTextChip] = useState('grey')
    const params = useParams()
    const navigate = useNavigate()
    const [pbData, setPbData] = useState({
        year: null,
        status: null,
        qty_goals: null,
        creating: null,
        last_checkin: null
    })
    const filterGoalsByUser = goals.filter(goal => goal.people_builder.player_id === playerId)
    const status = getStatusPeopleBuilder(filterGoalsByUser)

    const updateColorChip = useCallback(() => {
        if (status == 'Draft Goal' || status == 'Approval') {
            setColorChip('yellow')
            setColorTextChip('black')
        } else if (status == 'No Goals') {
            setColorChip('red')
            setColorTextChip('black')
        } else if (status == 'Needs Revision') {
            setColorChip('red')
            setColorTextChip('black')
        } else if (status == 'Active' || status == 'Completed') {
            setColorChip('green')
            setColorTextChip('white')
        } else {
            setColorChip('')
            setColorTextChip('')
        }

    })

    const updateStatus = useCallback(() => {
        setPbStatus(status)
    }, [status])

    const buildData = useCallback(() => {
        setPbData(prevStates => ({
            ...prevStates,
            qty_goals: filterGoalsByUser.length,
            status: filterGoalsByUser.length > 0 ? pbStatus : 'No Goals',
            creating: peopleBuilder?.created_at.split('T')[0],
            year: peopleBuilder?.year
        }))
    }, [filterGoalsByUser, peopleBuilder])

    const openGoalForm = () => {

        if (!filterGoalsByUser.length) {
            navigate(`/first-goal-pdf/${peopleBuilder?.id}`)
        } else {
            navigate(`/goal-form/people-builder/${peopleBuilder?.id}`)
        }

    }

    useEffect(() => {
        buildData()
        updateStatus()
        updateColorChip()
    }, [goals, pbStatus])

    useEffect(() => {
        if (params.userId === playerId) {
            setOpen(true)
        }
    }, [params.userId, peopleBuilder])

    return (
        <React.Fragment >
            <TableRow
                hover
                ref={ref}
                sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                        <Avatar src={peopleBuilder?.player.photo_url} />
                        <Typography variant='body1'>{peopleBuilder?.player.full_name}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                        <Avatar src={peopleBuilder?.coach?.photo_url ?? ''} />
                        <Typography variant='body1'>{peopleBuilder?.coach?.full_name ?? ''}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>{pbData.year}</TableCell>
                <TableCell>
                    <Chip sx={{ backgroundColor: colorChip, color: colorTextChip }} label={status} />
                </TableCell>
                <TableCell>{pbData.qty_goals}</TableCell>
                <TableCell>{pbData.creating}</TableCell>
                <TableCell>{pbData.last_checkin}</TableCell>
                {/* <TableCell>
					{isCoach || isPlayer ? (
						<Tooltip title='New Goal'>
							<IconButton onClick={openGoalForm}>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip title={`You do not have access to create new Goal`}>
							<Box>
								<IconButton disabled onClick={openGoalForm}>
									<AddCircleOutlineIcon />
								</IconButton>
							</Box>
						</Tooltip>
					)}
				</TableCell> */}
            </TableRow>
            <TableRow hover >
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Paper sx={{ m: 2, p: 2 }}>

                            <Typography
                                variant="h6"
                                textAlign='center'
                                gutterBottom
                                component="div"
                            >
                                Goals
                            </Typography>
                            <Goals peopleBuilder={peopleBuilder} playerId={playerId} getGoals={getGoals} goals={goals} />
                            <Stack sx={{ mt: 2, justifyContent: 'right' }} direction='row' alignItems='center' gap={1}>
                                <Avatar src={peopleBuilder?.coach?.photo_url ?? ''} />
                                <Typography variant='body1'>{peopleBuilder?.coach?.full_name ?? ''}</Typography>
                            </Stack>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Tooltip title="Add Goal">
                                    <IconButton >
                                        <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box> */}
                        </Paper>
                        <CheckIn goals={goals} title='Monthly Check-In' playerId={playerId} />
                        <RequiredCheckIn peopleBuilder={peopleBuilder} title='Required Check-In' playerId={playerId} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})

import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js'

const PeopleBuilder = () => {
    const { userId } = useParams()
    const [goals, setGoals] = useState([])
    const [title, setTitle] = useState([])
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [pbStatus, setPbStatus] = useState('')
    const [listName, setListName] = useState([])
    const [filteredPeopleBuilder, setFilteredPeopleBuilder] = useState([]);
    const [playerNameToFilter, setPlayerNameToFilter] = useState({})
    const [yearToFilter, setYearToFilter] = useState({})
    const [statusToFilter, setStatusToFilter] = useState([])
    const [openFilter, setOpenFilter] = useState(false)
    const { employees } = useContext(EmployeesContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { allPeopleBuilder, getPeopleBuilders, filterType, setFilterType } = useContext(PeopleBuilderContext)
    const [userPeopleBuilder, setUserPeopleBuilder] = useState()
    const ref = useRef(null)
    const statusOptions = [
        { label: 'Draft Goal' },
        { label: 'Approval' },
        { label: 'Active' },
        { label: 'Completed' },
        { label: 'No Goals' }
    ]

    const handleFilter = useCallback(() => {
        let filtered = userPeopleBuilder;

        if (playerNameToFilter?.label) {
            filtered = filtered.filter(user =>
                user.player.full_name.toLowerCase().includes(playerNameToFilter.label.toLowerCase())
            );
        }

        if (Object.keys(yearToFilter).length) {
            filtered = filtered.filter(user => user.year.toString() === yearToFilter?.label?.toString());
        }

        if (statusToFilter.length) {
            let userStatus = new Set()
            filtered = filtered.filter(user => {
                goals.map((goal) => {
                    if (goal.people_builder.id === user.id) {
                        userStatus.add(goal.goal_status)
                    } else {
                        userStatus.clear()
                    }
                })
                return statusToFilter.some(status => status?.label?.includes(pbStatus))

            });
        }

        setFilteredPeopleBuilder(filtered)
    }, [playerNameToFilter, yearToFilter, statusToFilter]);

    useEffect(() => {
        setFilteredPeopleBuilder(userPeopleBuilder)
    }, [userPeopleBuilder]);

    const peopleBuilders = async () => {
        setOpenBackdrop(true)
        const userLogged = JSON.parse(localStorage.getItem('user'))
        
        if (location.pathname.includes('personal')) {
            setTitle('Personal People Builder')
            const peopleBuilderFiltered = allPeopleBuilder.filter(user => user.player_id === userId)
            setFilteredPeopleBuilder(peopleBuilderFiltered)
        } else if (location.pathname.includes('team')) {
			setTitle('Team People Builder')
            const peopleBuilderFiltered = allPeopleBuilder.filter(user => user.coach_id === userId || user.player.coach === userLogged.full_name)
            setFilteredPeopleBuilder(peopleBuilderFiltered)
        } else {
			setTitle('Company People Builder')
            allPeopleBuilder.sort((a, b) => a.player.full_name.localeCompare(b.player.full_name))
            setFilteredPeopleBuilder(allPeopleBuilder)
        }

        if (filterType === 'personal') {
            setTitle('Personal People Builder')
        } else if (filterType === 'team') {
            setTitle('Team People Builder')
        } else if (filterType === 'all') {
            setTitle('Company People Builder')
        }

        setTimeout(() => {
            setOpenBackdrop(false)
        }, 1000)
    }

    const getGoals = useCallback(async () => {
        try {
            const response = await api.get(`/goals`)
            setGoals(response.data)

        } catch (error) {
            console.log(error)
        }
    })

    const gotoFirstGoalPage = (userId) => {
        navigate(`/first-goal-pdf/${userId}`)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (ref.current) {
                ref.current.style.animation = 'fade-in 0.5s ease-out forwards';
            }

        }, 1000)
        return () => clearTimeout(timer);

    }, [goals]);

    useEffect(() => {
        peopleBuilders()
        getGoals()
    }, [filterType])

    useEffect(() => {
        handleFilter();
    }, [handleFilter]);

    useEffect(() => {
        const listNameCheck = new Set()
        const newListName = []
        allPeopleBuilder.forEach((user) => {
            if (user.player.full_name && !listNameCheck.has(user.player.full_name)) {
                listNameCheck.add(user.player.full_name);
                newListName.push({ label: user.player.full_name });
            }
        });
        setListName(newListName);
    }, [userPeopleBuilder])

    useEffect(() => {
        getPeopleBuilders()
    }, [])

    useEffect(() => {
        peopleBuilders()
    }, [allPeopleBuilder])

    return (
        <Container maxWidth='xl' >
            {openBackdrop && (
                <BackdropLoader openBackdrop={openBackdrop} />
            )}
            <Card sx={{ mt: 4, maxHeight: 850, overflowY: 'auto' }}>
                <CardContent >
                    <Typography variant='h5' align='center'>{title}</Typography>
                    {openFilter && title !== 'Personal People Builder' && (
                        <Fade in={openFilter} timeout={500}>
                            <Paper elevation={2} sx={{ mt: 4, p: 2 }}>
                                <Stack direction='row' gap={2}>
                                    <InputSearch
                                        size={500}
                                        list={listName}
                                        value={playerNameToFilter?.label}
                                        onChange={(e, value) => setPlayerNameToFilter(value)}
                                        label='Player'
                                    />
                                    <InputSearch
                                        size={300}
                                        list={[{ label: '2024' }, { label: '2025' }]}
                                        value={yearToFilter || { label: new Date().getFullYear().toString() }}
                                        onChange={(e, value) => setYearToFilter(value)}
                                        label='Year'
                                    />
                                    <Autocomplete
                                        multiple
                                        sx={{ width: 400 }}
                                        id="tags-standard"
                                        options={statusOptions}
                                        onChange={(e, value) => setStatusToFilter(value)}
                                        value={statusToFilter}
                                        // getOptionLabel={(option) => option.label}
                                        // defaultValue={[statusOptions[13]]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Status"
                                            />
                                        )}
                                    />
                                </Stack>
                            </Paper>
                        </Fade>
                    )}
                    <TableContainer className={styles.tableContainer} component={Paper} sx={{ mt: 4 }}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead>
                                <TableRow >
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>
                                        {filterType !== 'personal' && (
                                            <IconButton onClick={() => setOpenFilter(!openFilter)}>
                                                <FilterAltIcon />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Player</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Coach</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Year</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Status</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Qty of Goals</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Creation</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Last Check-In</TableCell>
                                    {/* <TableCell sx={{ backgroundColor: '#ccc' }}>Actions</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredPeopleBuilder?.map((user) => (
                                    <Row
                                        key={user.id}
                                        ref={user.player_id === userId ? ref : null}
                                        pbStatus={pbStatus}
                                        setPbStatus={setPbStatus}
                                        playerId={user.player_id}
                                        getGoals={getGoals}
                                        goals={goals}
                                        peopleBuilder={user}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Container>
    );
}

export default PeopleBuilder