import React from 'react'
import { Button, Container, Box, Card, CardMedia, CardContent, CardActions, Typography } from '@mui/material'

const SlackAuth = () => {
    const redirectURI = process.env.REACT_APP_SLACK_REDIRECT_URI
    const clientId = process.env.REACT_APP_SLACK_CLIENT_ID

    console.log('redirectURI', redirectURI)
    console.log('clientId', clientId)
    
    const userScope = 'users.profile:read'
    return (

        <Container sx={{ textAlign: 'center', p: 4 }}>
            <Card sx={{ maxWidth: 345, margin: '0 auto' }}>
                <CardMedia
                    sx={{
                        height: '100px',
                        width: '300px',
                        margin: '0 auto',
                    }}
                    image="https://i.pcmag.com/imagery/reviews/07td46ju7p6lLVb0QGwc5VF-6.fit_scale.size_1028x578.v1569479844.jpg"
                    title="Slack"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Slack profile access required.
                        Enhances app experience.
                        Data used responsibly.
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                        sx={{
                            alignItems: 'center',
                            color: '#000',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '14px',
                            fontWeight: '600',
                            height: '44px',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: '204px'
                        }}
                        href={`https://slack.com/oauth/v2/authorize?user_scope=${userScope}&redirect_uri=${redirectURI}&client_id=${clientId}`}
                    >
                        Connect Slack
                    </Button>
                </CardActions>
            </Card>
        </Container>
    )

}

export default SlackAuth