// FirstGoal.jsx
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const FirstGoalPDF = ({ peopleBuilder }) => {
    const [pdf, setPdf] = useState(null);
    const googleDriveUrl = 'https://drive.google.com/file/d/1Q8nnBcQYP5mRt92d5gto4FS-9ou-YWYG/preview';
    const navigate = useNavigate()
    const { peopleBuilderId } = useParams()

    const openGoalForm = () => {
        navigate(`/goal-form/people-builder/${peopleBuilderId}`)
    }

    return (
        <Container>
            <Card sx={{ mt: 2, height: '85vh' }}>
                <Box sx={{ backgroundColor: 'grey' }}>
                    <Typography
                        sx={{ textAlign: 'center', p: 1 }}
                        variant='h5'
                    >
                        People Builder Goals Setting
                    </Typography>
                </Box>
                <CardContent>
                    <Paper sx={{ height: '70vh' }} elevation={2}>
                        <iframe style={{
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available'
                        }}
                            src={googleDriveUrl} />
                    </Paper>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={openGoalForm}
                        sx={{ m: 'auto' }}
                        variant='contained'
                    >
                        start your people builder
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default FirstGoalPDF;