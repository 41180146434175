import { GoogleOAuthProvider, useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import ListUsers from '../components/ListUsers.jsx'
import { useCallback, useContext, useEffect, useState } from 'react';
import { EmployeesContext } from '../context/UsersContext.js';
import api from '../apis/api.js'

const GoogleAuth = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [googleUser, setGoogleUser] = useState(localStorage.getItem('user'))
    const [slackToken, setSlackToken] = useState(localStorage.getItem('slackUserData'))
    const { fetchUsers } = useContext(EmployeesContext)

	console.log('slackToken', slackToken)
	console.log('googleUser', googleUser)
	
	
    useEffect(() => {
        fetchUsers()

        if (googleUser && slackToken) {
            navigate('/users')
        } else if (googleUser && !slackToken) {
            navigate('/slack-auth')
        } else if (!googleUser) {
            navigate('/')
        }

    }, [token, navigate, googleUser, slackToken])

    const loginWithGoogle = useCallback(async (response) => {

        try {
            const authResponse = await api.post(`/api/auth`, response, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            const data = authResponse.data

            localStorage.setItem('token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))
            
            console.log('authResponse', authResponse)

            if (authResponse.status === 200) {
                setToken(data.token)
                navigate('/slack-auth')
            } else {
                navigate('/not-auth')
            }

        } catch (e) {
            console.error('Error', e)
        }
    })

    return (
        <GoogleOAuthProvider clientId="769965408014-113aled7k6cf3djda7km6cd7fqhfmkl1.apps.googleusercontent.com">
            <Grid display={'none'} container justifyContent="center">
                <GoogleLogin
                    onSuccess={loginWithGoogle}
                    onError={() => console.log('Login Failed')}
                    useOneTap
                // auto_select={true}
                // cookiePolicy={'single_host_origin'}
                />
            </Grid>
        </GoogleOAuthProvider>
    )
}

export default GoogleAuth