import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Paper, Box, Container, Typography, IconButton, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import api from '../apis/api.js';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Delete from '@mui/icons-material/Delete.js';
import SnackbarAlert from './SnackbarAlert.jsx';
import BackdropLoader from './BackdropLoader.jsx';

const CheckIns = (props) => {
    const { goals } = props
    const [rowsData, setRowsData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    });
    const navigate = useNavigate();
    const [openLoader, setOpenLoader] = useState(false);

    const getCheckInData = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get('/checkins')
            const data = response.data
            const checkinPlayer = data.filter((checkin) => checkin.player_id === props.playerId && checkin.status !== 'archived')
            let checkinPlayerFiltered
            if (props.title === 'Monthly Check-In') {
                checkinPlayerFiltered = checkinPlayer.filter((checkin) => checkin.check_in_type === 'monthly-review')
            } else {
                checkinPlayerFiltered = checkinPlayer.filter((checkin) => checkin.check_in_type !== 'monthly-review')
            }

            const checkinData = checkinPlayerFiltered.map(checkin => ({
                id: checkin.id,
                month: checkin.monthly_ref,
                checkinDate: checkin.check_in_date.split('T')[0],
                meeting: checkin.meeting_date ? 'Yes' : 'No',
                goal_comments: checkin.goal_comments,
            }));

            setRowsData(checkinData);
        } catch (error) {
            console.error('Something went wrong:', error);
        } finally {
            setIsLoading(false);
        }
    }, [props.playerId, props.title])
    const columns = useMemo(() => [
        {
            field: 'month',
            headerName: 'Month',
            headerClassName: 'checkin-header',
            width: 300,
        },
        {
            field: 'checkinDate',
            headerName: 'Check-In Date',
            headerClassName: 'checkin-header',
            width: 400,
        },
        {
            field: 'meeting',
            headerName: '1:1 Meeting?',
            headerClassName: 'checkin-header',
            width: 300,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'checkin-header',
            width: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title="Delete Check-In">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(params.id)
                        }}>
                            <Delete />
                        </IconButton>
                    </Tooltip >
                )
            }
        }
    ], [])

    const handleClose = () => {
        setAlertConfig({ open: false })
    };

    const handleDelete = useCallback(async (id) => {
        setOpenLoader(true)
        try {
            const response = await api.delete(`/checkins/${id}`);

            if (response.status === 200) {
                setRowsData(prevData => prevData.filter(row => row.id !== id))
                getCheckInData()
                setOpenLoader(false)
            }

            setAlertConfig({
                messageText: 'Check-in deleted successfully',
                severityType: 'success',
                open: true
            })
        } catch (error) {
            console.error('Error deleting check-in:', error)
            setAlertConfig({
                messageText: 'Error deleting check-in',
                severityType: 'error',
                open: true
            })
            setOpenLoader(false)
        }
    }, [getCheckInData])

    const openCheckInForm = useCallback((id) => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === id)
        let userGoalsStatus = new Set()
        
        if (userGoals.length) {
            userGoals.map(goal => userGoalsStatus.add(goal.goal_status))
        }
        
        if (userGoalsStatus.has('Draft Goal') || userGoalsStatus.has('Approval') || userGoalsStatus.has('Needs Revision')) {
            setAlertConfig({
                messageText:'Goals should be Active',
                severityType: 'error',
                open: true
            })
            return
        }
        
        navigate(`/new-check-in/${id}`);
    }, [navigate])

    const openRefCheckInForm = useCallback((id) => {
        console.log('id', id)
        navigate(`/check-in/${id}`)
    }, [navigate])

    useEffect(() => {
        getCheckInData();
    }, [])

    return (
        <React.Fragment>
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            {openLoader && (
                <BackdropLoader openBackdrop={openLoader} />
            )}
            <Paper sx={{ p: 2, m: 2 }}>
                <Typography
                    variant="h6"
                    textAlign='center'
                    gutterBottom
                    component="div"
                >
                    {props.title}
                </Typography>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={rowsData}
                        disableColumnResize={true}
                        onRowClick={(params) => openRefCheckInForm(params.row.id)}
                        columns={columns}
                        rowSelection={false}
                        paginationModel={{ page: 0, pageSize: 12 }}

                        sx={{
                            // minHeight: 200,
                            '& .checkin-header': {
                                backgroundColor: '#ccc',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Tooltip title="Add Check-In">
                        <IconButton onClick={() => openCheckInForm(props.playerId)}>
                            <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Paper>
        </React.Fragment >
    )
}

export default React.memo(CheckIns)