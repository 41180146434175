import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import SnackbarAlert from './SnackbarAlert';
import { Paper, Box, Container, Typography, IconButton, Tooltip, CircularProgress, Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Delete from '@mui/icons-material/Delete.js';
import BackdropLoader from './BackdropLoader.jsx';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import api from '../apis/api.js'

const RequiredCheckIn = (props) => {
	const { playerId, peopleBuilder } = props
	const [rowsData, setRowsData] = React.useState([
		{
			id: 1,
			month: 'Mid-Year Review',
			checkinDate: '',
			meeting: 'No',
		},
		{
			id: 2,
			month: 'End-Year Review',
			checkinDate: '',
			meeting: 'No',
		}
	]);
	const [isLoading, setIsLoading] = React.useState(false)
	const [alertConfig, setAlertConfig] = useState({
		messageText: '',
		severityType: '',
		open: false
	});
	const navigate = useNavigate();
	const [openLoader, setOpenLoader] = useState(false)
	const [pbSettings, setPbSettings] = useState('')
	const [requiredCheckins, setRequiredCheckins] = useState([])

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const response = await api.get('/people-builder-settings')
				const settings = response.data.find(setting => setting.id === props.peopleBuilder.pb_settings_id)
				setPbSettings(settings)
			} catch (error) {
				console.log(error)
			}
		}
		fetchSettings()
		getRequiredCheckins()
	}, [])

	const getRequiredCheckins = async () => {

		try {
			const response = await api.get('/checkins')
			const requiredCheckins = response.data.filter(checkin => {
				return checkin.check_in_type !== "monthly-review" &&
					checkin.status !== 'archived'
			})

			console.log('requiredCheckins', requiredCheckins)

			setRequiredCheckins(requiredCheckins)
		} catch (error) {
			console.log(error)
		}

	}
	const months = useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'])

	useEffect(() => {
		const updateCheckinDates = () => {
			const midYearMonth = new Date(pbSettings?.mid_year_checkin).getUTCMonth()
			const endYearMonth = new Date(pbSettings?.end_year_checkin).getUTCMonth() + 1
			const currentMonth = new Date().getUTCMonth()
			let newRowsDate = []
			const requiredCheckinPlayer = requiredCheckins.filter(checkin => checkin.player_id === playerId)

			if (requiredCheckinPlayer.length) {
				const reqMidCheckin = requiredCheckinPlayer.filter(checkin => {
					return checkin.check_in_type === "mid-year-review"
				})

				if (reqMidCheckin.length) {
					newRowsDate.push({
						checkinDate: reqMidCheckin[0].check_in_date?.split('T')[0] ?? '',
						meeting: reqMidCheckin[0].meeting_date ? 'Yes' : 'No',
						id: reqMidCheckin[0].id,
						month: 'Mid-Year Review'
					})
				} else {
					if (midYearMonth < currentMonth) {
						newRowsDate.push({
							checkinDate: `Review is Due`,
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					} else if (midYearMonth > currentMonth) {
						newRowsDate.push({
							checkinDate: `Planned for ${months[midYearMonth]}`,
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					} else {
						newRowsDate.push({
							checkinDate: 'Review is Open',
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					}
				}

				const reqEndCheckin = requiredCheckinPlayer.filter(checkin => {
					return checkin.check_in_type === "end-year-review"
				})

				if (reqEndCheckin.length) {
					newRowsDate.push({
						checkinDate: reqEndCheckin[0].check_in_date?.split('T')[0] ?? '',
						meeting: reqEndCheckin[0].meeting ? 'Yes' : 'No',
						id: 2,
						month: 'Mid-Year Review'
					})
				} else {

					if (endYearMonth < currentMonth) {
						newRowsDate.push({
							checkinDate: `Review is Due`,
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					} else if (endYearMonth > currentMonth) {
						newRowsDate.push({
							checkinDate: `Planned for ${months[endYearMonth]}`,
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					} else {
						newRowsDate.push({
							checkinDate: 'Review is Open',
							meeting: 'No',
							id: 2,
							month: 'End-Year Review'
						})
					}

				}

				setRowsData(newRowsDate)
			}

		};

		updateCheckinDates()
	}, [pbSettings, requiredCheckins]);

	const columns = useMemo(() => [
		{
			field: 'month',
			headerName: 'Review',
			headerClassName: 'checkin-header',
			width: 300,
			renderCell: (params) => (
				<span style={
					((t) => {
						if (params.row.checkinDate === 'Review is Due') {
							return { fontWeight: 'bold', color: 'red' };
						} else if (params.row.checkinDate === '') {
							return { color: 'black' };
						} else if (params.row.checkinDate === 'Review is Open') {
							return { color: 'black' };
						}
					})()
				}>
					{params.row.month}
				</span>
			),
		},
		{
			field: 'checkinDate',
			headerName: 'Check-In Date',
			headerClassName: 'checkin-header',
			width: 400,
			renderCell: (params) => (

				params.row.checkinDate === 'Review is Open' ? (
					<Button
						color='success'
						variant='outlined'
						sx={{ textTransform: 'math-auto' }}
					>
						{params.row.checkinDate}
					</Button>
				) : (
					params.row.checkinDate === 'Review is Due' ? (
						<Button
							color='error'
							variant='outlined'
							sx={{ textTransform: 'math-auto' }}
						>
							{params.row.checkinDate}
						</Button>
					) : (
						<Typography
							variant='span'
						>
							{params.row.checkinDate}
						</Typography>

					)
				)
			),
		},
		{
			field: 'meeting',
			headerName: '1:1 Meeting?',
			headerClassName: 'checkin-header',
			width: 300,
			flex: 1
		}
	], [])

	const handleClose = () => {
		setAlertConfig({ open: false })
	};

	const openCheckInForm = useCallback((id) => {
		navigate(`/new-check-in/${id}`);
	}, [navigate])

	const openRefCheckInForm = useCallback((params) => {
		console.log(params.row.checkinDate)

		if (params.row.checkinDate === 'Review is Open') {

			if (params.row.month === 'Mid-Year Review') {
				navigate(`/new-mid-check-in/${playerId}`)
			} else {
				navigate(`/new-end-check-in/${playerId}`)
			}

		} else {
			navigate(`/check-in/${params.row.id}`)
		}

	}, [navigate])

	return (
		<React.Fragment>
			<SnackbarAlert
				open={alertConfig.open}
				messageText={alertConfig.messageText}
				severityType={alertConfig.severityType}
				handleClose={handleClose}
			/>
			{openLoader && (
				<BackdropLoader openBackdrop={openLoader} />
			)}
			<Paper sx={{ p: 2, m: 2 }}>
				<Typography
					variant="h6"
					textAlign='center'
					gutterBottom
					component="div"
				>
					{props.title}
				</Typography>

				{isLoading ? (
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						<CircularProgress />
					</Box>
				) : (
					<DataGrid
						rows={rowsData}
						disableColumnResize={true}
						disableRowSelectionOnClick
						onRowClick={(params) => {
							openRefCheckInForm(params)
						}}
						columns={columns}
						rowSelection={false}
						paginationModel={{ page: 0, pageSize: 12 }}
						sx={{
							// minHeight: 200,
							'& .checkin-header': {
								backgroundColor: '#ccc',
							},
							'& .MuiDataGrid-row': {
								cursor: 'pointer',
							},
							boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
						}}
					/>
				)}
				{/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Tooltip title="Add Check-In">
                        <IconButton onClick={() => openCheckInForm(props.playerId)}>
                            <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                        </IconButton>
                    </Tooltip>
                </Box> */}
			</Paper>
		</React.Fragment >
	)
}

export default RequiredCheckIn