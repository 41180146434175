// const fs = require('fs')
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button, Card, CardContent, Container, Avatar, Typography, Stack,
    Autocomplete, TextField, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Tooltip, Checkbox, FormGroup, FormControlLabel,
    Chip,
    IconButton,
} from '@mui/material';
import { EmployeesContext } from '../context/UsersContext.js';
import styles from '../css/Profile-page.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from './Loader.jsx'
import Strengths from './Strengths.jsx';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import api from '../apis/api.js'
import createTitle from '../apis/createNewTitle.js';
import SnackbarAlert from './SnackbarAlert.jsx';
import BackdropLoader from './BackdropLoader.jsx'
import { SlackContext } from '../context/SlackUserInfoContext.js';

const Profile = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const { slackUserStorage } = useContext(SlackContext)
    const { userId } = useParams()
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [showAddPhotoIcon, setShowAddPhotoIcon] = useState(false);
    const [photoOpacity, setPhotoOpacity] = useState(1);
    const [managerLabelChecked, setManagerLabelChecked] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    const [open, setOpen] = useState(false);
    const [openChangePhoto, setOpenChangePhoto] = useState(false);
    const [openDialogNewDepartment, setOpenDialogNewDepartment] = useState(false)
    const [openDialogNewTitle, setOpenDialogNewTitle] = useState(false)
    const [employee, setEmployee] = useState([])
    const [newDepartment, setNewDepartment] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
    const [selectedFields, setSelectedFields] = useState({
        department: '',
        departmentId: '',
        title: '',
        titleId: '',
        coach: '',
        manager: '',
        workLocation: '',
        workLocationId: '',
        phoneType: '',
        phone: ''
    })
    const [fileName, setFileName] = useState('')
    const [image, setImage] = useState('')
    const [coachList, setCoachList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [titles, setTitles] = useState([])
    const [departments, setDepartments] = useState([])
    const [workLocations, setWorkLocations] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [disabledField, setDisabledField] = useState(false)
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const fileInputRef = useRef(null)

    const handleClick = () => {
        fileInputRef.current.click();
    }
    var coachs = []
    var coachsValues = []
    var managers = []
    var managersValues = []

    const getTitles = useCallback(async () => {
        setTitles([])
        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/titles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var titles = await res.json()
                titles.sort((a, b) => a.title.localeCompare(b.title))
                titles.map((title) => {
                    setTitles(prevState => ([...prevState, { label: title.title, id: title.id }]))
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const getDepartments = useCallback(async () => {
        setDepartments([])

        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/departments`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var departments = await res.json()
                departments.sort((a, b) => a.department.localeCompare(b.department))
                departments.map((department) => {
                    setDepartments((prevStates) => ([...prevStates, { label: department.department, id: department.id }]))
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const getWorkLocations = useCallback(async () => {
        setWorkLocations([])
        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/work-locations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var workLocations = await res.json()
                workLocations.sort((a, b) => a.building_name.localeCompare(b.building_name))
                workLocations.map((location) => {
                    setWorkLocations((prevStates) => ([...prevStates, { label: location.building_name, id: location.id }]))
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const findCoachEmail = (coachName) => {
        const coachEmail = employees.find(employee => employee.full_name?.replaceAll('Coach: ', '') === coachName?.replaceAll('Coach: ', ''))
        return coachEmail?.email ?? ''
    }

    const handleClose = () => {
        setAlertConfig({ open: false })
    }

    const handleUpdateEmployee = useCallback(async () => {
        setLoadingSave(true)

        const updatedEmployeeData = {
            userId: userId,
            department_id: selectedFields?.departmentId ?? '',
            departmentText: selectedFields?.department ?? '',
            title_id: selectedFields?.titleId ?? '',
            titleText: selectedFields?.title ?? '',
            coach: selectedFields?.coach ?? '',
            direct_manager: selectedFields?.manager ?? '',
            work_location_id: selectedFields?.workLocationId ?? '',
            work_location_text: selectedFields?.workLocation ?? '',
            etro_type_phone: selectedFields?.phoneType ?? '' === 'ETRO Phone' ? 'work' : 'mobile',
            phone: selectedFields?.phone ?? ''
        }

        const employeeSelected = employees.find(employee => employee.id === userId)
        const coachSelected = employees.find(employee => employee.full_name === selectedFields?.coach)

        const peopleBuilderData = {
            coach_id: coachSelected.id
        }

        try {
            const response = await api.put(`/user/update/${employeeSelected.id}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                try {
                    const pbResponse = await api.patch(`/people-builders/${employeeSelected.id}`, peopleBuilderData)
                } catch (error) {
                    console.log(error)
                }
            }

            if (response.status === 200) {
                setLoadingSave(false)
                setAlertConfig({
                    messageText: 'Employee updated successfully',
                    severityType: 'success',
                    open: true
                })
                fetchUsers()
            } else {
                console.error('Something was wrong:', response);
                setAlertConfig({
                    messageText: response.message,
                    severityType: 'error',
                    open: true
                })
                setLoadingSave(false)
            }

        } catch (error) {
            console.error('Something was wrong:', error.message);
            setAlertConfig({ messageText: error.message, severityType: 'error', open: true })
            setLoadingSave(false)
        }

        // Updating Google Admin
        try {
            const response = await api.put(`/user/update/google/${userId}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        } catch (error) {
            console.error('Something was wrong:', error);
        }

    }, [selectedFields, employees])

    const employeeFitered = useCallback(() => {

        employees.map(employee => {

            if (!employee.etro_team) {
                return
            }

            if (!coachsValues.includes(employee.full_name) && employee.full_name !== '') {
                coachsValues.push(employee.full_name)
                coachs.push({ label: employee.full_name, id: employee.id })

            }

            if (!managersValues.includes(employee.direct_manager) && employee.direct_manager) {
                managersValues.push(employee.direct_manager)
                managers.push({ label: employee.direct_manager, id: employee.id })
            }

        })

        coachs.sort((a, b) => a?.label?.localeCompare(b?.label))
        workLocations.sort((a, b) => a?.label?.localeCompare(b?.label))

        setCoachList(coachs)
        setManagerList(managers)

    }, [employees])

    const updateSelectedField = (field, value) => {
        setSelectedFields(prevState => ({ ...prevState, [field]: value }));
    };

    const fetchAndFilterEmployeeInfo = useCallback(async () => {

        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var users = await res.json()
                const employee = users.find(emp => emp.id === userId);
                const employeeFixed = employees.find(emp => emp.id === userId);
                setOpenBackdrop(false)

                if (employee) {
                    updateSelectedField('departmentId', employee.departmentId);
                    updateSelectedField('department', employeeFixed?.department?.department ?? null);
                    updateSelectedField('titleId', employee.titleId);
                    updateSelectedField('title', employeeFixed?.title?.title ?? null);
                    updateSelectedField('coach', employee.coach);
                    updateSelectedField('manager', employee.direct_manager);
                    updateSelectedField('workLocationId', employee.work_location_id);
                    updateSelectedField('workLocation', employeeFixed?.work_location?.building_name ?? null);
                    updateSelectedField('phoneType', employee.etro_type_phone === 'work' ? 'ETRO Phone' : 'Personal Phone');
                    updateSelectedField('phone', employee.phone);
                }
            })
        } catch (e) {
            console.log(e)
        }


    }, [employees, userId]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenPhoto = () => {
        setOpenChangePhoto(true);
    };

    const handleClickOpenDialogNewDepartment = () => {
        setOpenDialogNewDepartment(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setOpenChangePhoto(false);
        setOpenDialogNewDepartment(false);
        setOpenDialogNewTitle(false);
        setFileName('')
    };

    const suspendUser = useCallback(async () => {
        setOpen(false)
        setLoadingSave(true)

        try {
            const response = await api.put(`/user/suspend/${employee.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ suspended: !employee.suspended }),
            })

            if (response.ok) {
                const data = await response.json()
                setEmployee(data.data[0])
                setLoadingSave(false)
                fetchUsers()
            } else {
                console.error('Something was wrong:', res);
                setLoadingSave(false)
            }

        } catch (error) {
            console.error('Something was wrong:', error);
        }

    }, [employee])

    const updateProfilePhoto = async () => {
        setOpenBackdrop(true)
        setOpenChangePhoto(false)
        const slack = await changeSlackPhoto()
        await changeGooglePhoto()
        setOpenBackdrop(false)
    }

    const changeSlackPhoto = async (e) => {

        const formData = new FormData()
        formData.append('image', image)

        try {
            const response = await api.post(`/user/update-slack-photo/${employee.id}`, formData)

            if (response.status === 200) {
                setAlertConfig({
                    messageText: 'Slack Profile Photo was updated Successfully',
                    open: true,
                    severityType: 'success'
                })
            } else {
                setAlertConfig({
                    messageText: 'Slack Profile Photo was not updated Successfully',
                    open: true,
                    severityType: 'error'
                })
            }

            return response
        } catch (error) {
            console.log(error)
            setAlertConfig({
                messageText: error.response.data.message,
                open: true,
                severityType: 'error'
            })
        }
    }

    const changeGooglePhoto = async (e) => {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('userData', JSON.stringify(employee))

        try {
            const response = await api.post('/change-google-photo', formData)
            if (response.status === 200) {
                setAlertConfig({
                    messageText: 'Google Profile Photo was updated Successfully',
                    open: true,
                    severityType: 'success'
                })
            } else {
                setAlertConfig({
                    messageText: 'Google Profile Photo was not updated Successfully',
                    open: true,
                    severityType: 'error'
                })
            }

            return response
        } catch (error) {
            setAlertConfig({
                messageText: error.message,
                open: true,
                severityType: 'error'
            })
        }
    }

    const createNewTitle = async (newTitle) => {
        const response = await createTitle(newTitle)
        setAlertConfig({
            messageText: response.data.message,
            severityType: response.data.status === 200 ? 'success' : 'error',
            open: true
        })
        handleCloseDialog()

    }

    useEffect(() => {
        employeeFitered()
        const employee = employees.find(employee => employee.id === userId);

        if (employee) {
            setEmployee(employee)
            setIsAdmin(employee.is_admin || employee.is_delegated_admin)

            if (employee.is_admin || employee.is_delegated_admin) {
                setDisabledField(true)
            }
        }

    }, [employeeFitered, userId, setSelectedFields])

    useEffect(() => {
        fetchAndFilterEmployeeInfo();
    }, [fetchAndFilterEmployeeInfo]);

    useEffect(() => {
        getTitles()
        getDepartments()
        getWorkLocations()
    }, [])

    const handleReturnToListUser = useCallback(() => {
        navigate('/users')
    })

    return (
        <Container maxWidth="lg" >
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Stack spacing={2}>
                        <Card>
                            <CardContent>
                                <Stack sx={{ 'alignItems': 'center' }} direction="row" spacing={2}>
                                    <Stack className={styles.withTotal} direction="row" alignItems='center' spacing={2}>
                                        <Stack direction="row">
                                            <Avatar
                                                src={employee?.photo_url}
                                                sx={{ width: 56, height: 56, opacity: photoOpacity }}
                                                onMouseEnter={() => {
                                                    setShowAddPhotoIcon(true)
                                                    setPhotoOpacity(0.5)
                                                }}
                                                onMouseLeave={() => {
                                                    setShowAddPhotoIcon(false)
                                                    setPhotoOpacity(1)
                                                }}
                                            />
                                            {showAddPhotoIcon && !isAdmin && (
                                                <AddAPhotoIcon
                                                    style={{ margin: '0.4rem', cursor: 'pointer', fontSize: 40, position: 'absolute' }}
                                                    onMouseEnter={() => {
                                                        setShowAddPhotoIcon(true)
                                                        setPhotoOpacity(0.5)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setShowAddPhotoIcon(false)
                                                        setPhotoOpacity(1)
                                                    }}
                                                    onClick={handleClickOpenPhoto}
                                                >
                                                    <AddAPhotoIcon style={{ opacity: 0.5 }} />
                                                </AddAPhotoIcon>
                                            )}
                                        </Stack>
                                        <Stack spacing={2}>
                                            <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.full_name}</Typography>
                                            <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.email}</Typography>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <Chip
                                                sx={{ backgroundColor: '#ffeb3b' }}
                                                label={employee.etro_team ? 'ETRO Team' : 'Not ETRO Team'}
                                            />
                                            {employee.is_admin || employee.is_delegated_admin && (
                                                <Chip
                                                    sx={{ backgroundColor: '#ffeb3b' }}
                                                    label='Admin'
                                                />
                                            )}

                                        </Stack>
                                        <Card className={styles.marginLeft}>
                                            <Typography sx={{ 'textAlign': 'center' }} >CliftonStrengths®</Typography>
                                            <CardContent>
                                                <Strengths employeeId={employee.id} />
                                            </CardContent>
                                        </Card>

                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Stack spacing={2} direction='row' alignItems={'center'}>
                            <Autocomplete id="department"
                                disabled={disabledField}
                                options={departments}
                                sx={{ width: '50%' }}
                                value={selectedFields.department}
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    updateSelectedField('department', newValue?.label);
                                    updateSelectedField('departmentId', newValue?.id);
                                }}
                                renderInput={(params) => <TextField {...params} label="Department" variant="standard" />}
                            />
                            <Tooltip
                                sx={{ cursor: "pointer" }}
                                title="Add new Department"
                                placement='top'
                            >
                                <IconButton
                                    disabled={disabledField}
                                    onClick={handleClickOpenDialogNewDepartment}
                                >
                                    <EditIcon
                                        disabled={disabledField}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Autocomplete id="title"
                                disabled={disabledField}
                                options={titles}
                                sx={{ width: '50%' }}
                                value={selectedFields.title}
                                onChange={(event, newValue) => {
                                    updateSelectedField('title', newValue?.label)
                                    updateSelectedField('titleId', newValue?.id)
                                }}
                                renderInput={(params) => <TextField {...params} label="Title" variant="standard" />}
                            />
                            <Tooltip sx={{ cursor: "pointer" }} title="Add new Title" placement='top'>
                                <IconButton
                                    disabled={disabledField}
                                    onClick={() => setOpenDialogNewTitle(true)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack spacing={2} direction='row' alignItems={'center'}>
                            <Autocomplete id="coach"
                                disabled={disabledField}
                                options={coachList}
                                sx={{ width: '50%' }}
                                value={selectedFields.coach}
                                onChange={(event, newValue) => {
                                    updateSelectedField('coach', newValue?.label);
                                    if (managerLabelChecked) {
                                        updateSelectedField('manager', findCoachEmail(newValue?.label))
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Coach" variant="standard" />}
                            />
                            <Autocomplete id="manager"
                                disabled={disabledField}
                                options={managerList}
                                variant="standard"
                                sx={{ width: '50%' }}
                                value={selectedFields.manager}
                                onChange={(event, newValue) => {
                                    updateSelectedField('manager', newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    onKeyDown={(e) => {

                                        !managerLabelChecked ? '' : e.preventDefault()
                                    }}

                                    label={
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{ maxHeight: '20px' }}
                                                control={<Checkbox checked={managerLabelChecked} />}
                                                label="Direct Manager is the same as the Coach"
                                                onChange={(event) => {
                                                    setManagerLabelChecked(event.target.checked)
                                                    event.target.checked ? updateSelectedField('manager', findCoachEmail(selectedFields.coach)) : ''
                                                }}
                                            >
                                                <Checkbox color="primary" />
                                            </FormControlLabel>
                                        </FormGroup>
                                    }
                                    variant="standard" />}

                            />
                        </Stack>
                        <Stack spacing={2} direction='row' alignItems={'center'} >
                            <Autocomplete id="work-location"
                                disabled={disabledField}
                                options={workLocations}
                                sx={{ width: '60%' }}
                                value={selectedFields.workLocation}
                                onChange={(event, newValue) => {
                                    updateSelectedField('workLocation', newValue?.label);
                                    updateSelectedField('workLocationId', newValue?.id);
                                }}
                                renderInput={(params) => <TextField {...params} label="Work Location" variant="standard" />}
                            />
                            <Tooltip
                                sx={{ cursor: "pointer" }}
                                title="Add new Work Location"
                                placement='top'
                            >
                                <IconButton
                                    disabled={disabledField}
                                    onClick={() => { navigate('/work-locations') }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Autocomplete id="Phone-type"
                                disabled={disabledField}
                                options={['ETRO Phone', 'Personal Phone']}
                                sx={{ width: '20%' }}
                                value={selectedFields.phoneType}
                                onChange={(event, newValue) => {
                                    updateSelectedField('phoneType', newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Phone Type" variant="standard" />}
                            />
                            <TextField id="phone outlined-basic"
                                disabled={disabledField}
                                label='Phone'
                                variant="standard"
                                sx={{ width: '20%' }}
                                value={selectedFields.phone}
                                onChange={(event) => {
                                    updateSelectedField('phone', event.target.value);
                                }}
                            />
                        </Stack>
                        <Stack direction="row" spacing={10} useFlexGap justifyContent="flex-end">
                            <Tooltip title="This action will suspend the User's ETRO accounts" placement="top">
                                <Button
                                    disabled={disabledField}
                                    onClick={handleClickOpen}
                                    sx={{ backgroundColor: '#f44336' }}
                                    variant="contained"
                                >
                                    {employee.suspended ? 'Activate' : 'Suspend'}
                                </Button>
                            </Tooltip>

                            <Stack direction='row' spacing={2}>
                                <Button
                                    onClick={() => { handleReturnToListUser() }}
                                    color='error'
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleUpdateEmployee}
                                    disabled={disabledField}
                                    color='success'
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>

                    </Stack>
                </CardContent>
                {loadingSave && <Loader />}
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to suspend {employee.full_name} accounts.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={suspendUser}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogNewDepartment}
                onClose={handleClose}
                aria-labelledby="alert-dialog-department"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            label="Enter new Department"
                            onChange={(e) => {
                                setNewDepartment(e.target.value)
                            }}
                        />
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            onClick={handleCloseDialog}
                            variant="outlined"
                        >Cancel</Button>
                        <Button
                            onClick={() => {
                                createNewTitle(newDepartment)
                            }}
                            variant="outlined"
                        >Confirm</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openChangePhoto}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack spacing={2}>
                            {fileName.length <= 0 ? (
                                <Stack>
                                    <Button variant="contained" component="span" onClick={handleClick}>
                                        Upload File
                                    </Button>
                                </Stack>
                            ) : (
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    value={fileName}
                                    variant="standard"
                                    disabled />
                            )
                            }
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={(e) => {
                                    setImage(e.target.files[0])
                                    setFileName(e.target.files[0].name)
                                }}
                                style={{ display: 'none' }}
                            />

                            <Stack spacing={0.5}>
                                <span><b>Format:</b> JPG or PNG.</span>
                                <span><b>Size:</b> Between 10 KB and 5 MB.</span>
                                <span><b>Recommended resolution:</b> 720 px tall, 720 px wide.</span>
                                <span><b>Minimum resolution:</b> 250 px tall, 250 px wide.</span>
                                <span><b>Quality:</b> The photo should be in focus and well lit, and have no significant
                                    alterations or excessive use of filters.
                                    In other words, the image should represent reality.</span>
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                    >Cancel</Button>
                    {/* <form action={`${process.env.REACT_APP_BASE_URL}/auth/google`}> */}
                    <Button
                        type='submit'
                        onClick={() => {
                            updateProfilePhoto()
                        }}
                        variant="outlined"
                    >Confirm</Button>

                    {/* </form> */}

                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogNewTitle}
                onClose={handleClose}

            >
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            label="Enter new Title"
                            onChange={(e) => {
                                setNewTitle(e.target.value)
                            }}
                        />
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            onClick={handleCloseDialog}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                createNewTitle(newTitle)
                            }}
                            variant="outlined"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

        </Container >
    );
}

export default Profile;