import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import {
	Card, CardContent, Paper, Typography,
	Switch, Container, Chip, Stack,
	TextField, List, ListItem, ListItemText,
	Link, Button, CardActions, Grid,
	Divider, IconButton, ImageList, ImageListItem, useMediaQuery,
	TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
	Tooltip
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CheckinGoals from './CheckinGoals';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { EmployeesContext } from '../context/UsersContext.js';
import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js';
import api from '../apis/api.js';
import SnackbarAlert from "./SnackbarAlert.jsx"
import { useParams, useNavigate } from 'react-router-dom';
import BackdropLoader from './BackdropLoader.jsx';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const CheckInForm = () => {
	const [checked, setChecked] = React.useState(false);
	const [player, setPlayer] = useState({})
	const [selectedDate, setSelectedDate] = useState(null)
	const [peopleBuilderSettings, setPeopleBuilderSettings] = useState([])
	const { employees } = useContext(EmployeesContext)
	const { userPeopleBuilder } = useContext(PeopleBuilderContext)
	const location = useLocation()
	const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
	const { playerId, checkinId } = useParams();
	const [userLogged] = useState(JSON.parse(localStorage.getItem('user')))
	const [isCoach, setIsCoach] = useState(false)
	const [isPlayer, setIsPlayer] = useState(false)
	const [checkinData, setCheckinData] = useState({
		monthly_ref: months[moment().month()],
		meeting_date: null,
		check_in_date: moment(),
		general_comments: null,
		status: 'active',
		creation_owner_id: userLogged.id,
		coach_id: null,
		player_id: playerId,
		check_in_type: null
	})
	const [checkin, setCheckin] = useState([])
	const navigate = useNavigate()
	const [openLoader, setOpenLoader] = useState(false)
	const [checkinGoalData, setCheckinGoalData] = useState([{
		checki_in_id: 0,
		goal_id: 0,
		check_in_goal_status: '',
		goal_comments: ''
	}])
	const [alertConfig, setAlertConfig] = useState({
		messageText: '',
		severityType: '',
		open: false
	})
	const [fileDescription, setFileDescription] = useState('')
	const [fileUrl, setFileUrl] = useState('')
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [descriptionRef, setDescriptionRef] = useState(null);
	const [urlRef, setUrlRef] = useState(null);
	const [params] = useState(useParams())
	const [hover, setHover] = useState(false)

	const getPlayer = useCallback(() => {
		let player = employees.find((employee) => employee.id === playerId)

		if (player) {
			setPlayer(player)
			setIsCoach(userLogged.id === player?.id)
			setIsPlayer(userLogged.id === player?.id)
		} else {
			player = employees.find((employee) => employee.id === checkin.player_id)
			setPlayer(player)
			setIsCoach(userLogged.id === player?.id)
			setIsPlayer(userLogged.id === player?.id)
		}
	}, [employees, checkin])

	// const getCheckinType = (date) => {

	// 	const newDate = date || new Date()
	// 	const midYearCheckin = new Date(peopleBuilderSettings[0]?.mid_year_checkin).getUTCMonth()
	// 	const endYearCheckin = new Date(peopleBuilderSettings[0]?.end_year_checkin).getUTCMonth()

	// 	if (midYearCheckin === new Date(newDate).getUTCMonth()) {
	// 		setCheckinData((prevStates) => ({ ...prevStates, check_in_type: 'mid-year-review' }))
	// 	} else if (endYearCheckin === new Date(newDate).getUTCMonth()) {
	// 		setCheckinData((prevStates) => ({ ...prevStates, check_in_type: 'end-year-review' }))
	// 	} else {
	// 		setCheckinData((prevStates) => ({ ...prevStates, check_in_type: 'monthly-review' }))
	// 	}
	// }

	const getPeopleBuilderSettingsActive = async () => {
		try {
			const response = await api.get(`/people-builder-settings`)
			setPeopleBuilderSettings(response.data)
		} catch (error) {
			console.log(error)
		}
	}

	const findCoachFromUserLogged = () => {
		const userLoggedData = employees.find((employee) => employee.id === userLogged.id)
		const coach = employees.find((employee) => employee.full_name === userLoggedData?.coach)
		setCheckinData((prevStates) => ({ ...prevStates, coach_id: coach?.id }))
	}

	const handleSwitchChange = (event) => {
		setChecked(event.target.checked)

		if (!event.target.checked) {
			setCheckinData((prevStates) => ({
				...prevStates,
				meeting_date: null,
				check_in_date: new Date(),
				monthly_ref: months[moment().month()]
			}))
			// getCheckinType(new Date())
		}
		setSelectedDate(null)
	}

	const createCheckin = async () => {
		setOpenLoader(true)
		setCheckinData((prevStates) => ({ ...prevStates, files: JSON.stringify(uploadedFiles) }))
		const checkinGoalsData = checkinGoalData.filter((goal) => goal.check_in_goal_status === '')

		if (checkinGoalsData.length) {
			setOpenLoader(false)
			setAlertConfig({
				messageText: 'Please fill status in the goals',
				severityType: 'error',
				open: true
			})
			return
		}
		const data = {
			...checkinData,
			files: JSON.stringify(uploadedFiles),
			checkinGoalData
		}
		try {
			const response = await api.post('/checkins', data)

			if (response.status === 201) {
				setAlertConfig({
					messageText: 'Check-in created successfully',
					severityType: 'success',
					open: true
				})

				if (isCoach) {
					navigate(`/team-people-builder/${playerId}`)
				} else if (isPlayer) {
					navigate(`/personal-people-builder/${playerId}`)
				} else {
					navigate(`/company-people-builder/${playerId}`)
				}
			}
			setOpenLoader(false)
		} catch (error) {
			console.log(error)
			setAlertConfig({
				messageText: 'Error creating check-in',
				severityType: 'error',
				open: true
			})
			setOpenLoader(false)
		}
	}

	const updateCheckin = async () => {

		setCheckinData((prevStates) => ({ ...prevStates, files: JSON.stringify(uploadedFiles) }))
		setOpenLoader(true)
		const data = {
			...checkinData,
			files: JSON.stringify(uploadedFiles),
			checkinGoalData
		}
		try {
			const response = await api.put(`/checkins/${checkinId}`, data)

			if (response.status === 200) {
				setAlertConfig({
					messageText: 'Check-in updated successfully',
					severityType: 'success',
					open: true
				})

				if (isCoach) {
					navigate(`/team-people-builder/${player.id}`)
				} else if (isPlayer) {
					navigate(`/personal-people-builder/${player.id}`)
				} else {
					navigate(`/company-people-builder/${player.id}`)
				}

			}

			setOpenLoader(false)
		} catch (error) {
			console.log(error)
			setAlertConfig({
				messageText: 'Error updating check-in',
				severityType: 'error',
				open: true
			})
			setOpenLoader(false)
		}
	}

	const handleClose = () => {
		setAlertConfig({ open: false })
	}

	const uploadFile = async () => {
		if (!fileDescription.trim()) {
			descriptionRef?.focus();
			return;
		}
		if (!fileUrl.trim()) {
			urlRef?.focus();
			return;
		}

		setUploadedFiles([...uploadedFiles, { id: uploadedFiles.length + 1, description: fileDescription, url: fileUrl, uploaded_at: new Date().toISOString().split('T')[0] }]);
		setFileDescription('');
		setFileUrl('');
	}

	const getCheckin = async () => {
		try {
			const response = await api.get(`/checkins`)
			const checkin = response.data.find((checkin) => checkin.id === parseInt(checkinId))

			if (checkin) {
				setUploadedFiles(JSON.parse(checkin.files))
			}

			if (checkin) {
				const player = employees.find((employee) => employee.id === checkin.player_id)
				setPlayer(player)
				setCheckin(checkin)
				setCheckinData(checkin)
				checkin.meeting_date ? setChecked(true) : setChecked(false)
				setSelectedDate(moment(checkin?.meeting_date?.split('T')[0]))
			}

		} catch (error) {
			console.log(error)
		}
	}

	const removeUploadedFile = (fileToRemove) => {
		setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== fileToRemove));
	};

	useEffect(() => {
		getCheckin()
		getPlayer()

		if (location.pathname.includes('mid')) {
			setCheckinData((prevStates) => ({
				...prevStates,
				check_in_type: 'mid-year-review'
			}))
		} else if (location.pathname.includes('end')) {
			setCheckinData((prevStates) => ({
				...prevStates,
				check_in_type: 'end-year-review'
			}))
		} else {
			setCheckinData((prevStates) => ({
				...prevStates,
				check_in_type: 'monthly-review'
			}))
		}

	}, [])

	useEffect(() => {
		findCoachFromUserLogged()
		getPeopleBuilderSettingsActive()
		// getCheckinType()
		getPlayer()
		getCheckin()
	}, [employees, checkinId])

	return (
		<Container>
			<SnackbarAlert
				open={alertConfig.open}
				messageText={alertConfig.messageText}
				severityType={alertConfig.severityType}
				handleClose={handleClose}
			/>
			{openLoader && (
				<BackdropLoader openBackdrop={openLoader} />
			)}
			<Card sx={{ mt: 4, maxHeight: '80vh', overflow: 'auto' }}>
				<CardContent>
					<Paper elevation={2} sx={{ mb: 2, p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Grid item display={matchesSmallScreen ? 'none' : 'block'}>
							<ImageList>
								<ImageListItem sx={{ width: '8rem', margin: 0 }}>
									<img src="https://iili.io/J1MU5vV.png" />
								</ImageListItem>
							</ImageList>
						</Grid>
						<Typography
							sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
							variant='span'>Player: <Chip label={player?.full_name} /> </Typography>
						<Typography
							sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
							variant='span'>Coach: <Chip label={player?.coach} /> </Typography>
						<Typography
							sx={{ width: '20%', fontSize: 14, fontWeight: 'bold' }}
							variant='span'>2024-06-01</Typography>
					</Paper>
					<Typography align='center' variant='h6'>Monthly Check-Ins</Typography>
					<Paper elevation={2} sx={{ mt: 2, mb: 2, p: 2, minHeight: 90 }}>
						<Stack direction='row' spacing={2} alignItems='center'>
							<Stack direction='row' spacing={2} alignItems='center'>
								<Typography sx={{ fontSize: 14, fontWeight: 'bold' }} variant='span'>In Person Meeting:</Typography>
								<Chip color={!checked ? 'error' : 'default'} sx={{ ml: 2, mr: 2, cursor: 'pointer' }} label='No' />
								<Switch
									color="default"
									checked={checked}
									onChange={handleSwitchChange}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								<Chip color={checked ? 'success' : 'default'} sx={{ ml: 2, cursor: 'pointer', mr: 2 }} label='Yes' />
							</Stack>
							<LocalizationProvider
								dateAdapter={AdapterMoment}
							>
								<DatePicker
									label="Meeting Date"
									sx={{ visibility: checked ? 'visible' : 'hidden', width: '50%' }}
									value={selectedDate}
									onChange={(newValue) => {
										setSelectedDate(newValue)
										setCheckinData((prevStates) => (
											{
												...prevStates,
												meeting_date: newValue,
												check_in_date: newValue,
												monthly_ref: months[newValue.month()]
											}
										))
										// getCheckinType(newValue)
									}}
									renderInput={(params) => <TextField {...params} />}
									format='YYYY-MM-DD'
								/>
							</LocalizationProvider>
						</Stack>
					</Paper>
					<Paper>
						<CheckinGoals
							checkinGoalData={checkinGoalData}
							setCheckinGoalData={setCheckinGoalData}
							checkin={checkin}
						/>
					</Paper>
					<TextField
						id="outlined-multiline-static"
						label="General Comments"
						multiline
						fullWidth
						value={checkinData.general_comments || ''}
						sx={{ mt: 2 }}
						rows={4}
						maxRows={4}
						variant='outlined'
						onChange={(e) => setCheckinData((prevStates) => ({ ...prevStates, general_comments: e.target.value }))}
					/>

					<Tooltip followCursor title='Click to view Coach & Player Prompts'>
						<Paper
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
							onClick={() => window.open('https://drive.google.com/file/d/1UqHwLlEbPx7eOZ1A7Jb2qTPPhYMV-ebK/view', '_blank')}
							elevation={4}
							sx={{ mt: 2, p: 2, cursor: 'pointer' }}
						>
							<Typography textAlign='center' variant="h5" color="initial">Coach & Player Prompts - 1:1 Check in</Typography>
							<Stack direction='row' spacing={2}>
								<Stack direction='row' spacing={2}>
									<List>
										<Typography variant="h6" color="initial">Coach Prompts:</Typography>
										<ListItem sx={{ fontSize: 10 }}>
											<Stack>
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="What part of your day/work makes the day go by fast?" />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="What part of your day/work makes the day go by slowly?" />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="As your coach, what can I do to offer more guidance in your goals?" />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="As your coach, what am I doing well?" />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="What are some things you are proud of accomplishing or being part of recently?
                                    " />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="At our last check in, you said ____ was a challenge. How is that going for you
                                        now?" />
											</Stack>
										</ListItem>
									</List>
								</Stack>
								<Stack>
									<List>
										<Typography variant="h6" color="initial">Player Prompts:</Typography>
										<ListItem>
											<Stack>
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="If I could ask for help on one goal, it would be…" />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="I really enjoy when I'm given the responsibility or opportunity to ___
                                        because____." />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="I find I struggle when I'm given the responsibility or opportunity to____
                                        because____.
                                    " />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="I'd like to commit to ____ to move forward in my goals before we meet again.
                                    " />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="I'm really proud of accomplishing ___ since we met last." />
												<ListItemText
													primaryTypographyProps={{ fontSize: 14 }}
													primary="After learning about my Clifton Strengths, I'd like to utilize my ___ more at
                                        work. Some
                                        ideas I have are ____.
                                    " />
											</Stack>
										</ListItem>
									</List>
								</Stack>
							</Stack>
						</Paper>
					</Tooltip>
					<Stack direction='row' spacing={2} sx={{ mt: 2, mb: 2 }}>
						<TextField
							id="outlined-multiline-static"
							label="Description"
							value={fileDescription}
							sx={{ mt: 2, width: '40%' }}
							variant='outlined'
							onChange={(e) => setFileDescription(e.target.value)}
							inputRef={setDescriptionRef}
						/>
						<TextField
							id="outlined-multiline-static"
							label="URL"
							value={fileUrl}
							fullWidth
							sx={{ mt: 2, width: '60%' }}
							variant='outlined'
							onChange={(e) => setFileUrl(e.target.value)}
							inputRef={setUrlRef}
						/>
						<Button onClick={uploadFile} variant='outlined'>Upload</Button>
					</Stack>
					<Container>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Description</TableCell>
										<TableCell>Uploaded At</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{uploadedFiles?.map((file) => (
										<TableRow
											hover
											key={file.description}
											sx={{ cursor: 'pointer' }}
											onClick={() => {
												if (file.url.includes('http://') || file.url.includes('https://')) {
													window.open(file.url, '_blank')
												} else {
													setAlertConfig({
														messageText: 'Wrong URL',
														severityType: 'error',
														open: true
													})
												}
											}}
										>
											<TableCell>{file.description}</TableCell>
											<TableCell>{file.uploaded_at}</TableCell>
											<TableCell>
												<Tooltip title="Delete File">
													<IconButton
														onClick={(e) => {
															e.stopPropagation()
															removeUploadedFile(file.id)
														}}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Container>
					<Divider />
				</CardContent>
				<CardActions sx={{ justifyContent: 'end', mb: 2 }}>
					<Button color='error' variant='contained' onClick={() => {
						navigate(-1)
					}}>Cancel</Button>
					<Button color='success' variant='contained' onClick={params.checkinId ? updateCheckin : createCheckin}>Save</Button>
				</CardActions>

			</Card>

		</Container>
	)
}

export default CheckInForm